import {styled} from '@mui/system'

export const PasswordRecoveryPageStyled = styled('div')(({theme}) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#F0F3F4',
  a: {
    color: '#000000de'
  },
  '.login-form-container': {
    boxShadow: '5px 5px 10px rgb(0 0 0 / 5%)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(210),
    padding: theme.spacing(8)
  },
  '.img-container': {
    margin: 'auto'
  },
  '.img-style': {
    width: '100%'
  },
  '.form-container': {
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column'
  },
  '.form-text': {
    marginBottom: theme.spacing(3)
  },
  '.form-item': {
    paddingBottom: theme.spacing(8),
    display: 'inline-grid'
  },
  '.send-button': {
    color: '#fff',
    borderRadius: theme.spacing(21),
    fontSize: theme.spacing(9),
    fontWeight: 500,
    textTransform: 'none !important',
    height: theme.spacing(27),
    marginBottom: theme.spacing(8)
  },
  '.sponsored-containter': {
    fontSize: theme.spacing(6),
    alignSelf: 'end',
    display: 'flex'
  },
  '.img-images': {
    marginLeft: theme.spacing(2),
    width: theme.spacing(50)
  },
  '.error-message': {
    color: '#f05050',
    textAlign: 'center'
  }
}))
