import {Button, Typography} from '@mui/material'
import {useState} from 'react'
import LoginTextField from 'src/components/elements/TextFields/LoginTextField/LoginTextField'
import {FistStepStyled} from './FirstStepStyled'
import apiClient from 'src/services/apiClient'

type FirstStepProps = {
    setStep: any,
    config: any,
    registration: any,
    setRegistration: any
}

function emailIsValid (email: string) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/.test(email)
}

const FirstStep: React.FC<FirstStepProps> = ({setStep, config, registration, setRegistration}) => {
  const [statusValidation, setStatusValidation] = useState<{message: string, isValid: boolean}>({message: '', isValid: false})

  const handleTextFieldChange = (e: any) => {
    setRegistration({
      ...registration,
      [e.target.name]: e.target.value
    })
    setStatusValidation({isValid: true, message: ''})
  }

  const handleSubmit = async () => {
    if (!registration?.email) {
      setStatusValidation({isValid: false, message: "l'email è obbligatoria"})
      return
    }
    if (!registration?.code) {
      setStatusValidation({isValid: false, message: 'il codice è obbligatorio'})
      return
    }
    let isEmailValid
    try {
      isEmailValid = await apiClient.checkEmail(registration?.email, () => {})
    } catch {
      isEmailValid = true
    }
    if (!isEmailValid) {
      setStatusValidation({isValid: false, message: "L'email non è valida"})
      return
    }

    if (!emailIsValid(registration?.email)) {
      setStatusValidation({isValid: false, message: "il formato dell'email non è corretto"})
      return
    }

    let isCodeValid
    try {
      ({valid: isCodeValid} = await apiClient.checkRegistrationToken(registration?.code, () => {}))
    } catch {
      isCodeValid = false
    }

    if (!isCodeValid) {
      setStatusValidation({isValid: false, message: 'Codice non valido'})
      return
    }

    setStep(1)
  }

  return (
    config ?
      <FistStepStyled>
        <div className='login-form-container'>
          {config.logoPSP &&
            <div className='img-container'>
              <img alt='logoPSP' className='img-style' src={`data:image/png;base64,${config.logoPSP}`} />
            </div>}
          <div>
            <div style={{display: 'flex', justifyContent: 'center', fontWeight: 500, fontSize: '30px'}}>
              {'Crea il tuo account'}
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {'Crea il tuo account per poter accedere ai servizi a te riservati'}
            </div>
          </div>
          <div className='form-container'>
            <div className='form-item'>
              {!statusValidation.isValid &&
                <Typography className='form-text error-message' variant='body2'>
                  {statusValidation?.message}
                </Typography>}
              <Typography className='form-text' variant='body2'><b>{'Indirizzo e-mail'}</b></Typography>
              <LoginTextField
                loginConfiguration={config}
                name='email'
                onChange={handleTextFieldChange}
                placeholder='Indirizzo e-mail'
                value={registration.email}
                variant='outlined'
              />
            </div>
            <div className='form-item'>
              <Typography className='form-text' variant='body2'><b>{'Codice di attivazione'}</b></Typography>
              <LoginTextField
                loginConfiguration={config}
                name='code'
                onChange={handleTextFieldChange}
                placeholder='Codice di attivazione'
                value={registration.code}
                variant='outlined'
              />
            </div>
            <Button
              className='login-button'
              onClick={handleSubmit}
              sx={{
                backgroundColor: config.primaryColor,
                '&:hover': {
                  backgroundColor: config.primaryColor
                }
              }}
              variant='contained'
            >
              {'REGISTRAMI'}
            </Button>
          </div>
        </div>
      </FistStepStyled> :
      <></>
  )
}

export default FirstStep
