import {Button, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import LoginTextField from 'src/components/elements/TextFields/LoginTextField/LoginTextField'
import {PATHS, STATUS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'
import {LoginPageStyled} from './LoginPageStyled'
import {Link, useHistory} from 'react-router-dom'
import {dispatch} from 'src/redux-store'
import {updateScope} from 'src/slices/pspConfigSlice'

const LoginPage = () => {
  const [user, setUser] = useState({identifier: '', password: ''})
  const [config, setConfig] = useState<any>()
  const [statusLogin, setStatusLogin] = useState<string>()
  const history = useHistory()

  const handleTextFieldChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
    if (statusLogin === STATUS.REJECTED) {
      setStatusLogin(undefined)
    }
  }

  const handleSubmit = async () => {
    await apiClient.login(user, setStatusLogin)
  }

  useEffect(() => {
    async function getLoginConfig () {
      const response = await apiClient.loginConfiguration()
      setConfig(response)
    }
    getLoginConfig()
  }, [])

  useEffect(() => {
    if (statusLogin === STATUS.FULFILLED) {
      history.push('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusLogin])

  useEffect(() => {
    if (config?.scope) {
      dispatch(updateScope(config.scope))
    }
    if (config?.PSPTitleAndIcon) {
      const link = document.querySelector('link[rel="shortcut icon"]')
      const title = document.querySelector('title')
      if (title) {
        document.title = config.PSPTitleAndIcon.title
      }
      if (link) {
        link.setAttribute('href', `data:image/png;base64,${config.PSPTitleAndIcon.icon}`)
      }
    }
  }, [config])

  return (
    config ?
      <LoginPageStyled>
        <div className='login-form-container'>
          {config.logoPSP &&
            <div className='img-container' style={{padding: '0px 16px'}}>
              <img alt='logoPSP' className='img-style' src={`data:image/png;base64,${config.logoPSP}`} />
            </div>}
          <div className='form-container'>
            <div className='form-item'>
              {statusLogin === STATUS.REJECTED &&
                <Typography className='form-text error-message' variant='body2'>
                  {'Nome utente o password errati'}
                </Typography>}
              <Typography className='form-text' variant='body2'>{'Utente*'}</Typography>
              <LoginTextField
                loginConfiguration={config}
                name='identifier'
                onChange={handleTextFieldChange}
                placeholder='Username'
                value={user.identifier}
                variant='outlined'
              />
            </div>
            <div className='form-item'>
              <Typography className='form-text' variant='body2'>{'Password*'}</Typography>
              <LoginTextField
                loginConfiguration={config}
                name='password'
                onChange={handleTextFieldChange}
                placeholder='Password'
                type='password'
                value={user.password}
                variant='outlined'

              />
            </div>
            <Button
              className='login-button'
              onClick={handleSubmit}
              sx={{
                backgroundColor: config.primaryColor,
                '&:hover': {
                  backgroundColor: config.primaryColor
                }
              }}
              variant='contained'
            >
              {'Log in'}
            </Button>
            <Link to={PATHS.PASSWORD_RECOVERY}>
              <Typography className='form-text' variant='body2'>{'Password dimenticata?'}</Typography>
            </Link>
            {config?.hasAutoregistration &&
              <Link to={PATHS.AUTO_REGISTRATION}>
                <Typography className='form-text' variant='body2'>{'Registrami'}</Typography>
              </Link>}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {config.logoIA &&
                <div className='img-images' style={{marginTop: '20px'}}>
                  <img alt='logoIA' className='img-style' src={`data:image/png;base64,${config.logoIA}`} />
                </div>}
              {config.logoSponsorPsp &&
                <div className='sponsored-containter'>
                  {config.sponsorText ?
                    <div style={{marginTop: '20px'}}>
                      {config.sponsorText}
                      <img alt='logoPSP' className='img-images' src={`data:image/png;base64,${config.logoSponsorPsp}`} />
                    </div> :
                    <img alt='logoPSP' src={`data:image/png;base64,${config.logoSponsorPsp}`} style={{width: '140px'}} />}
                </div>}
            </div>
          </div>
        </div>
      </LoginPageStyled> :
      <></>
  )
}

export default LoginPage
