
import apiClient from 'src/services/apiClient'
import {ThirdStepStyled} from './ThirdStepStyled'
import {useState} from 'react'
import {Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import Header from 'src/components/templates/Header/Header'

type ThirdStepProps = {
    config: any,
    email: any
}

const ThirdStep: React.FC<ThirdStepProps> = ({config, email}) => {
  const [status, setStatus] = useState<any>(undefined)

  const sendEmail = async () => {
    await apiClient.sendConfirmSelfRegistration(email, setStatus)
  }

  return (
    config ?
      <ThirdStepStyled>
        <Header showMenu={false} />
        <div className='card-container'>
          <div className='message-container' style={{fontSize: '28px', textAlign: 'center', paddingTop: '20px'}}>
            {'Ti abbiamo inviato una mail alla casella di posta che hai indicato in fase di registrazione. Clicca sul link contenuto per confermare la tua registrazione e accedere ai servizi.'}
            <span className='span-link' onClick={() => sendEmail()} style={{textDecoration: 'underline'}}>{'Richiedi il rinvio della email'}</span>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => { setStatus(undefined) }}
          open={status === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>
                {'Si è verificato un errore'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
      </ThirdStepStyled> :
      <></>
  )
}

export default ThirdStep
