/* eslint-disable react/prop-types */
import React, {ReactNode, useState} from 'react'
import {
  Box,
  LabelDisplayedRowsArgs,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import ActionsMenu, {Action} from '../Menus/ActionMenu/ActionsMenu'
import {visuallyHidden} from '@mui/utils'
import {mdiDotsVertical} from '@mdi/js'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {TableCustomStyled} from './TableCustomStyled'
import {TABLE_ICONS} from 'src/constants/constants'
import Icon from '@mdi/react'
import moment from 'moment'
import {Checkbox} from 'antd'
import Loader from '../Loader/Loader'

export type TableColumn = {
  id: string;
  label?: string;
  minWidth?: number;
  align?: "right";
  type?: string;
  format?: string;
  default?: string;
  defaultValue?: string;
  render?: (value: any) => ReactNode;
  noSort?: boolean
};

export type PaginationType = {
  page: number, rowsPerPage: number, order: string
}

type Order = "asc" | "desc";

type TableCustomProps = {
  columns: TableColumn[];
  rows: Record<string, any>[];
  actions?: Action[];
  isLoading?: boolean;
  showPagination?: boolean;
  pagination: PaginationType;
  totalElements: number;
  onPaginationChange: (pagination: PaginationType) => void;
  onRowClick?: (row: Record<string, any>) => void;
};

const TableCustom: React.FC<TableCustomProps> = ({
  columns,
  rows = [],
  actions,
  isLoading,
  showPagination = true,
  pagination,
  totalElements,
  onPaginationChange,
  onRowClick
}) => {
  const [order, setOrder] = useState<Order>('asc')

  const isEqualWithoutHyphen = (str1: string, str2: string) => {
    if (str1.startsWith('-')) {
      str1 = str1.substring(1)
    }

    return str1 === str2
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ): void => {
    const isAsc = isEqualWithoutHyphen(pagination.order, property) && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    onPaginationChange({...pagination, order: isAsc ? property : `-${property}`})
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    onPaginationChange({...pagination, page: newPage - 1})
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onPaginationChange({page: 0, rowsPerPage: +event.target.value, order: pagination.order})
  }

  const EnhancedTableHead = (
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  ) => {
    const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
      }

    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                sortDirection={isEqualWithoutHyphen(pagination.order, headCell.id) ? order : false}
              >
                {headCell.label && headCell.noSort &&
                  <b>
                    {headCell.label.toUpperCase()}
                  </b>}
                {headCell.label && !headCell.noSort &&
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={isEqualWithoutHyphen(pagination.order, headCell.id)}
                    direction={isEqualWithoutHyphen(pagination.order, headCell.id) ? order : 'asc'}
                    hideSortIcon={headCell.noSort}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {isEqualWithoutHyphen(pagination.order, headCell.id) ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ?
                          'sorted descending' :
                          'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>}
              </TableCell>
            )
          })}
          {actions && (
            <TableCell />
          )}
        </TableRow>
      </TableHead>
    )
  }

  const labelDisplayedRows = (info: LabelDisplayedRowsArgs) => {
    const currentPage = info.page + 1
    const totalPages = info.count / pagination.rowsPerPage

    return (
      <span className='page-info'>
        <span className='page-counter'>{`${currentPage} DI ${Math.ceil(totalPages === Infinity ? 1 : totalPages)}`}</span>
        <span className='total-elements'>{`Totale elementi: ${info.count}`}</span>
      </span>
    )
  }

  const defaultFallback = (row: any, id: string, defaultProp?: string) => {
    if (row[id] !== undefined) {
      return row[id]
    } else if (defaultProp) {
      return row[defaultProp]
    }
  }

  const parseActions = (row: any, actionsRow?: any) => {
    return actionsRow.map((action: any) => {
      if (((action.condition && action.condition(row)) || !action.condition)) {
        return (action)
      }
      return undefined
    }).reduce((accum: any, element: any) => {
      if (element !== undefined) {
        accum.push(element)
      }
      return accum
    }, [])
  }

  const percentageStyle = (value: number) : React.CSSProperties => {
    if (value > 99) {
      return {
        background: 'green',
        color: 'black',
        WebkitPrintColorAdjust: 'exact'
      }
    }
    if (value <= 99 && value >= 97) {
      return {
        background: 'lightgreen',
        color: 'black',
        WebkitPrintColorAdjust: 'exact'
      }
    }
    if (value <= 96 && value >= 90) {
      return {
        background: 'yellow',
        color: 'black',
        WebkitPrintColorAdjust: 'exact'
      }
    }
    if (value <= 89 && value >= 80) {
      return {
        background: 'orange',
        color: 'black',
        WebkitPrintColorAdjust: 'exact'
      }
    }
    if (value <= 79) {
      return {
        background: 'red',
        color: 'black',
        WebkitPrintColorAdjust: 'exact'
      }
    }

    return {}
  }

  const renderCellValue = (column: TableColumn, value: any) => {
    if (column.render) {
      return column.render(value)
    }

    if (TABLE_ICONS[column.id]) {
      return <Icon path={TABLE_ICONS[column.id]} size={1} />
    }

    if (Array.isArray(value)) {
      return value
        .slice(0, 2)
        .map((text, index) => (
          <Typography
            color='#58666e'
            key={index}
            variant={index === 0 && value.length > 1 ? 'h5' : 'body2'}
          >
            {column.format && column.type === 'date' && text ? moment(text).format(column.format) :
              (column.type === 'boolean' ? <Checkbox checked={text} disabled /> :
                (column.defaultValue && !text ? column.defaultValue : text)
              )}
          </Typography>
        ))
    }

    return (
      <Typography variant='body2'>
        {column.format && column.type === 'date' && value ? moment(value).format(column.format) :
          (column.type === 'boolean' ? <Checkbox checked={value} disabled /> :
            (column.defaultValue && !value ? column.defaultValue : value)
          )}
      </Typography>
    )
  }

  return (
    <TableCustomStyled>
      <TableContainer>
        {isLoading ?
          <Loader /> :
          <Table aria-label='table'>
            {EnhancedTableHead(handleRequestSort)}
            <TableBody>
              {rows.length === 0 && (<TableRow><TableCell>{'Nessun dato trovato'}</TableCell></TableRow>)}
              {rows.map((row: Record<string, any>) => {
                return (
                  <TableRow
                    hover
                    key={row._id}
                    tabIndex={-1}
                  >
                    {columns.map(column => {
                      const value = defaultFallback(row, column.id, column.default)
                      return (
                        <TableCell
                          align={column.align}
                          key={column.id}
                          onClick={() => onRowClick ? onRowClick(row) : undefined}
                          style={column.type === 'percentage' ? percentageStyle(value) : undefined}
                        >
                          {renderCellValue(column, value)}
                        </TableCell>
                      )
                    })}
                    {actions && (
                      <TableCell className='action-cell'>
                        {parseActions(row, actions).length > 0 &&
                          <ActionsMenu actions={parseActions(row, actions)} data={row} icon={mdiDotsVertical} />}
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>}

      </TableContainer>
      {showPagination &&
        <TablePagination
          ActionsComponent={(info) => (
            <Pagination
              count={Math.ceil(totalElements / info.rowsPerPage)}
              onChange={handleChangePage}
              page={pagination.page + 1}
              showFirstButton
              showLastButton
            />
          )}
          component='div'
          count={totalElements}
          labelDisplayedRows={labelDisplayedRows}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100, 250]}
        />}
    </TableCustomStyled>
  )
}

export default TableCustom
