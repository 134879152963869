import {styled} from '@mui/system'
import {STYLE} from 'src/constants/constants'

export const NavBarStyled = styled('div')(({theme}) => ({
  height: `calc(100% - ${theme.spacing(STYLE.SIDEBAR_MARGIN_HEADER_TOP)})`,
  marginTop: `${theme.spacing(STYLE.SIDEBAR_MARGIN_HEADER_TOP)}`,
  width: `${theme.spacing(STYLE.SIDEBAR_WIDTH)}`,
  backgroundColor: theme.palette.primary.main,
  paddingTop: `${theme.spacing(45)}`,
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '.nav-bar-bottom-logo': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginRight: 15,
    marginBottom: 15
  }
}))
