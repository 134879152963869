import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import {SecondStepStyled} from './SecondStepStyled'
import {Form, Input, Radio, Select} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import {Snackbar, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import Header from 'src/components/templates/Header/Header'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {CONSENTS} from '../../selfRegistrationConstants'
import TimeslotsSelector from 'src/components/elements/TimeslotsSelector/TimeslotsSelector'
import {TimeslotProps} from 'src/types/types'

const POSTAL_CODE_REGEX = /^[0-9]*$/
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/

const consentOptions = [
  {value: 'CONSENT', label: 'Esprimo il consenso'},
  {value: 'NOT_CONSENT', label: 'Nego il consenso'}
]

type Option = {
  value: string,
  label: string,
}

type SecondStepProps = {
    setStepPage: any,
    config: any,
    email: any,
    registrationCode: any
}

const SecondStep: React.FC<SecondStepProps> = ({config, setStepPage, email, registrationCode}) => {
  const [status, setStatus] = useState<any>(undefined)
  const [statusRegistration, setStatusRegistration] = useState<any>(undefined)
  const [regions, setRegions] = useState<Option[]>([])
  const [provinces, setProvinces] = useState<Option[]>([])
  const [cities, setCities] = useState<Option[]>([])
  const [regionQuery, setRegionQuery] = useState<string>('')
  const [provinceQuery, setProvinceQuery] = useState<{search?: string, region?: string}>({})
  const [cityQuery, setCityQuery] = useState<{search?: string, province?: string}>({})
  // const [isUsernameValid, setIsUsernameValid] = useState<boolean>(true)
  const [step, setStep] = useState<number>(0)
  const [formValues, setFormValues] = useState<any>({})
  const [timeSlotValues, setTimeSlotValues] = useState<TimeslotProps[]>([])
  const [timeSlotValidation, setTimeSlotValidation] = useState<boolean>(true)

  const [form] = useForm()

  async function getRegions () {
    const regionsData = await apiClient.getRegions(regionQuery, setStatus)
    setRegions(regionsData)
  }

  async function getProvinces () {
    form.setFieldValue('province', undefined)
    form.setFieldValue('city', undefined)
    const provincesData = await apiClient.getProvinces(provinceQuery, setStatus)
    setProvinces(provincesData)
  }

  async function getCities () {
    form.setFieldValue('city', undefined)
    const citiesData = await apiClient.getCities(cityQuery, setStatus)
    setCities(citiesData)
  }

  // async function checkUsername (username:string) {
  //   const validUsername = await apiClient.checkUsername(username, setStatus)
  //   setIsUsernameValid(validUsername)
  // }

  useEffect(() => {
    form.setFieldValue('country', 'Italia')
    form.setFieldValue('email', email)
    getRegions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getRegions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionQuery])

  useEffect(() => {
    getProvinces()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceQuery])

  useEffect(() => {
    getCities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityQuery])

  const onFinish = async (values: Record<string, any>) => {
    delete formValues.confirmPassword
    delete values.consentPassword

    const privacyConsents: string[] = []
    CONSENTS.forEach((checkbox: any) => {
      if (values[checkbox.value] === 'CONSENT') {
        privacyConsents.push(checkbox.value)
      }
      delete values[checkbox.value]
    })

    const body = {
      ...formValues,
      birthDate: values.birthDate?.toISOString(),
      privacyConsents,
      preferredTimeSlots: timeSlotValues,
      token: registrationCode
    }

    await apiClient.postSelfRegistration(body, setStatusRegistration)
  }

  useEffect(() => {
    if (statusRegistration === STATUS.FULFILLED) {
      setStepPage(2)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusRegistration])

  const renderConsentCheckbox = () => {
    return CONSENTS.map((checkbox:any) => (
      <Form.Item
        className='consent'
        key={checkbox.value}
        label={checkbox.label}
        name={checkbox.value}
        rules={[
          {required: true, message: 'Questo campo è obbligatorio'},
          () => ({
            validator (_, value) {
              if ((checkbox.required && value === 'CONSENT') || !checkbox.required) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Questo consenso è obbligatorio'))
            }
          })
        ]}
      >
        <Radio.Group options={consentOptions} />
      </Form.Item>
    ))
  }

  return (
    config ?
      <SecondStepStyled>
        <Header showMenu={false} />
        {
          <div className='card-container'>
            {step === 0 &&
              <>
                <div style={{display: 'flex', flexDirection: 'column', fontSize: '26px', fontWeight: 600, textAlign: 'center'}}>
                  <b>{'Benvenuto ✍🏻!'}</b>
                  <b>{'Prima di accedere al tuo portale,'}</b>
                  <b>{'ti chiediamo un paio di dati:'}</b>
                </div>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='mainData' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      className='firstName'
                      label='Nome'
                      name='firstName'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'}
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='lastName'
                      label='Cognome'
                      name='lastName'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'}
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='password'
                      label='Password'
                      name='password'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {pattern: PASSWORD_REGEX, message: 'Password troppo debole, assicurarsi che sia almeno da 8 caratteri, che abbia almeno una lettere maiuscola e una minuscola, che contenga un numero e un carattere speciale (tra !@#$%^&*())'}
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      className='confirmPassword'
                      label='Conferma password'
                      name='confirmPassword'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        ({getFieldValue}) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Le due password non corrispondono!'))
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      className='fiscalCode'
                      label='Codice fiscale'
                      name='fiscalCode'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='cellPhoneNumber'
                      label='Numero cellulare'
                      name='cellPhoneNumber'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='email'
                      label='Email'
                      name='email'
                    >
                      <Input disabled />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='address' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      className='state'
                      label='Stato'
                      name='country'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        disabled
                        getPopupContainer={(trigger) => trigger}
                      />
                    </Form.Item>
                    <Form.Item
                      className='region'
                      label='Regione'
                      name='region'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onChange={(value) => setProvinceQuery({...provinceQuery, region: value})}
                        onSearch={(value) => setRegionQuery(value)}
                        options={regions}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='province'
                      label='Provincia'
                      name='province'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onChange={(value) => setCityQuery({...cityQuery, province: value})}
                        onSearch={(value) => setProvinceQuery({...provinceQuery, search: value})}
                        options={provinces}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='city'
                      label='Città'
                      name='city'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onSearch={(value) => setCityQuery({...cityQuery, search: value})}
                        options={cities}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='address'
                      label='Indirizzo'
                      name='address'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='streetNumber'
                      label='Civico'
                      name='streetNumber'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='postalCode'
                      label='Codice Postale'
                      name='postalCode'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {
                          pattern: POSTAL_CODE_REGEX,
                          message: 'Formato codice postale non valido'
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='locality'
                      label='Località'
                      name='locality'
                    >
                      <Input />
                    </Form.Item>

                  </Form>
                </CardCustom>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Fasce orarie preferite per il contatto telefonico'}</Typography>}>
                  {!timeSlotValidation &&
                    <Typography className='form-text error-message' variant='body2'>
                      {'Compila tutti i campi'}
                    </Typography>}
                  <TimeslotsSelector onChange={(value) => { setTimeSlotValues(value); setTimeSlotValidation(true) }} value={timeSlotValues} />
                </CardCustom>
                <div className='button-container'>
                  <div />
                  <PrimaryButton
                    className='submit-button'
                    onClick={() => {
                      const validity = timeSlotValues.every((timeslot: any) => timeslot.day && timeslot.from && timeslot.to)
                      setTimeSlotValidation(validity)
                      if (!validity) {
                        return
                      }
                      form.validateFields().then((values) => {
                        setFormValues(values)
                        setStep(step + 1)
                      })
                    }}
                    variant='contained'
                  >
                    {'Avanti'}
                  </PrimaryButton>
                </div>
              </>}
            {step === 1 &&
              <>
                <div className='img-container'>
                  <img alt='logoPSP' className='img-style' src={'/assets/images/logo_IA.png'} />
                </div>
                <b style={{padding: '16px', textAlign: 'center'}}>{"Termini e condizioni d'uso del Servizio Digitale Demo"}</b>
                <div style={{marginBottom: '8px'}}>
                  <div className='cookie-card'>
                    <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='cookiePolicy' /></Typography>}>
                      <p>{"Utilizziamo cookie tecnici per garantire il corretto funzionamento del sito. Cliccando su 'Accetto', acconsenti all'uso di tali cookie. Per ulteriori informazioni consulta il documento di "}
                        <a href={'https://ia-internal-portals-test.italiassistenza-rte7p02.gcp.mia-platform.eu/static-files/cookie-policy.pdf'} rel='noreferrer' style={{textDecoration: 'underline'}} target='_blank'>{'cookie policy.'}</a>
                      </p>
                    </CardCustom>
                  </div>
                </div>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='consensus' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                    style={{display: 'block'}}
                  >

                    <p><b>{'Informativa ai sensi dell’art. 13 Regolamento UE 679/2016'}</b></p>
                    <p style={{width: 'max-content'}}>{"Letto di aver letto l'informativa il/la sottoscritto dichiara di aver compreso il contenuto."}</p>

                    {renderConsentCheckbox()}
                    <div style={{marginBottom: '16px'}}>{"Il Consenso espresso è in ogni momento revocabile, senza pregiudicare la liceità del trattamento basata sul consenso fornito prima della revoca, inviando una comunicazione all'indirizzo email: demo@italiassistenza.it"}</div>
                    <Form.Item
                      className='consentPassword'
                      label='Reinserisci la password per confermare i consensi'
                      name='consentPassword'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        ({getFieldValue}) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Le password non corrisponde con quella precedenti!'))
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <div className='button-container'>
                  <SecondaryButton
                    className='submit-button'
                    onClick={() => {
                      setStep(step - 1)
                      form.setFieldsValue(formValues)
                    }}
                    variant='contained'
                  >
                    {'Indietro'}
                  </SecondaryButton>
                  <PrimaryButton
                    className='submit-button'
                    onClick={() => form.submit()}
                    variant='contained'
                  >
                    {'Invia'}
                  </PrimaryButton>

                </div>
              </>}
          </div>
        }
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => { setStatus(undefined) }}
          open={statusRegistration === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom onClose={() => { setStatusRegistration(undefined) }} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>
                {'Si è verificato un errore'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => { setStatus(undefined) }}
          open={status === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>
                {'Si è verificato un errore'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
      </SecondStepStyled> :
      <></>
  )
}

export default SecondStep
