import React, {useState, useEffect} from 'react'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import Filters, {FilterItem} from '../../Filters/Filters'
import {WeightStyle} from './WeightStyle'
import WeightDialog from '../../WeightDialog/WeightDialog'
import apiClient from 'src/services/apiClient'
import {STATUS} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {Snackbar, Typography} from '@mui/material'

const Weight: React.FC<{
  patientId: string,
  patientInfo: Record<'firstName' | 'lastName', string>
}> = ({
  patientId,
  patientInfo
}) => {
  const [filters, setFilters] = useState([])
  const [selectedRow, setSelectedRow] = useState<Record<string, any>>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-date'
  })
  const [data, setData] = useState<any>([])
  const [count, setCount] = useState<number>(0)

  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)

  const fetchData = async () => {
    const fetchedData = await apiClient.getPatientWeights(patientId, filters, pagination, setStatus) ?? []
    const parsedData = fetchedData.map((weight: any, i: number) => ({
      ...weight,
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      _id: i
    }))
    setData(parsedData)
  }

  const fetchDataCount = async () => {
    const fetchedDataCount = await apiClient.getPatientWeightsCount(patientId, filters, setStatusCount) ?? []
    setCount(fetchedDataCount)
  }

  useEffect(() => {
    fetchData()
    fetchDataCount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, filters, pagination])

  const columns: TableColumn[] = [
    {
      id: 'date',
      label: 'DATA',
      type: 'date',
      format: 'ddd DD MMM YYYY'
    },
    {
      id: 'lastName',
      label: 'COGNOME'
    },
    {
      id: 'firstName',
      label: 'NOME'
    },
    {
      id: 'weight',
      label: 'PESO'
    }
  ]

  const filterConfig: FilterItem[] = [
    {
      id: 'date',
      label: 'Data',
      type: 'rangePicker'
    }
  ]

  const onSubmitFilters = (newFilters: any) => {
    setFilters(newFilters)
    setPagination(prev => ({...prev, page: 0}))
  }

  return (
    <WeightStyle>
      <Filters config={filterConfig} key='filters' onSubmit={onSubmitFilters} />
      <TableCustom
        columns={columns}
        isLoading={status === STATUS.PENDING}
        onPaginationChange={setPagination}
        onRowClick={setSelectedRow}
        pagination={pagination}
        rows={data}
        totalElements={count}
      />
      {selectedRow &&
        <WeightDialog onClose={() => setSelectedRow(undefined)} record={selectedRow} />}

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined); setStatusCount(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </WeightStyle>
  )
}

export default Weight
